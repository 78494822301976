.bidboard-accordion .accordion-button {
  padding: 8px;
}

.bidboard-accordion .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bidboard-accordion .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card .bidboard-card-footer {
  font-size: 14px;
  margin-top: 0.5rem;
}

.bidboard-accordion .bidboard-card-footer {
  font-size: 14px;
}

.bidboard-accordion .accordion-body {
  padding: 0px;
}

.card .bidboard-card-footer:last-child {
  border-radius: 0;
}

.accordion-body .list-group-item:last-child {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.bidboard-accordion .accordion-header {
  --bs-accordion-active-color: var(--bs-light);
  --bs-accordion-active-bg: var(--bs-success-border-subtle);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(15, 81, 50, 0.5);
}

/* Hack to style the accordion button :-/ */
.bidboard-accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23adb5bd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.bidboard-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23adb5bd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
