#root, .bidboard-modal, .bidboard-popover {
  font-family: 'Jura', sans-serif !important;
  line-height: normal !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bs-gray-800);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bs-gray-600);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bs-gray-500);
}

#info-table {
  text-align: center;
  margin-bottom: 0.5rem;
  table-layout: fixed;
}

#info-table td {
  font-weight: 300;
  font-size: 16px;
  vertical-align: top;
}

#info-table th {
  vertical-align: bottom;
}

.bidboard-th {
  font-size: 14px;
}

/* Hide button text on small screens */
@media only screen and (max-width: 768px) {
  .bidboard-button-text {
    display: none;
  }
}

.form-control:focus, .form-select:focus {
  border-color: var(--bs-success) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px var(--bs-success) !important;
}

.dropdown-item:active, option:hover {
  background-color: var(--bs-success) !important;
}